import * as React from 'react'
import ReactDOM from 'react-dom'
import ReCAPTCHA from 'react-google-recaptcha'

import { CaptchaInterface, CaptchaSize } from './types'

interface CaptchaProps {
  siteKey: string
  onVerify?: (response: string) => void
  size?: CaptchaSize
  containerRef?: React.MutableRefObject<HTMLDivElement | null>
}

const captchaOptions = (
  onVerify: (response: string) => void,
  ref,
  siteKey: string,
  size?: CaptchaSize,
) => {
  return (
    <ReCAPTCHA ref={ref} size={size} onChange={onVerify} sitekey={siteKey} />
  )
}

export const Captcha = React.forwardRef<
  CaptchaInterface | undefined,
  CaptchaProps
>((props, ref) => {
  return typeof document !== 'undefined' && document.body
    ? ReactDOM.createPortal(
        captchaOptions(props.onVerify, ref, props.siteKey, props.size),
        props.containerRef?.current
          ? props.containerRef.current
          : document.body,
      )
    : null
})
